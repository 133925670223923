<template>
  <div>
    <startup-card />
    <b-card title="Raporlar">
      <b-row>
        <b-col
          v-for="item in dataList"
          :key="item.id"
          cols="12"
          md="4"
          class="mb-2"
        >
          <div class="border rounded p-2 text-center height-150 d-flex align-items-center justify-content-center">
            <div>
              <div class="font-weight-bold text-primary font-medium-2">
                {{ item.startup_report }}
              </div>
              <div
                class="d-flex align-items-center justify-content-center mt-1"
                :class="item.status === '1'? 'text-success' : 'text-muted'"
              >
                <FeatherIcon
                  :icon="item.status === '1'? 'CheckCircleIcon' : 'ClockIcon'"
                />
                {{ item.status === '1'? 'Tamamlandı' : 'Beklemede' }}
              </div>
              <div
                v-if="item.id_startup_reports !== '1'"
                class="mt-1"
              >
                <template v-if="item.status === '1'">
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    :to="item.url + $route.params.id"
                  >
                    <FeatherIcon icon="CheckIcon" /> Raporu Görüntüle
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    variant="primary"
                    size="sm"
                    :to="item.url + $route.params.id"
                  >
                    <FeatherIcon icon="CheckIcon" /> Şimdi Tamamla
                  </b-button>
                </template>
              </div>
              <div
                v-else
                class="mt-1"
              >
                <template v-if="item.status !== '1'">
                  <b-button
                    variant="primary"
                    size="sm"
                    :to="item.url + $route.params.id"
                  >
                    <FeatherIcon icon="CheckIcon" /> Şimdi Tamamla
                  </b-button>
                </template>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import StartupCard from '@/views/App/Interests/Requests/components/StartupCard.vue'
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'MyStartupView',
  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    StartupCard,
  },
  computed: {
    dataList() {
      return this.$store.getters['memberStartups/memberStartupReports']
    },
  },
  created() {
    this.getStartup()
  },
  methods: {
    getStartup() {
      this.$store.dispatch('memberStartups/memberStartupView', this.$route.params.id)
    },
  },
}
</script>
