<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              size="64"
              variant="primary"
              :text="startup.avatar_text"
            />
            <div class="ml-1 border-left pl-1">
              <b-card-title class="mb-0">
                {{ startup.title }}
              </b-card-title>
              <b-card-sub-title class="mt-0 pt-0">
                {{ startup.company }}
              </b-card-sub-title>
              <div class="mt-1">
                {{ startup.description }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-end"
        >
          <div class="font-medium-3 font-weight-bolder text-primary">
            {{ startup.startup_status }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardSubTitle, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'StartupCard',
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
  },
  computed: {
    startup() {
      return this.$store.getters['memberStartups/getMemberStartup']
    },
  },
}
</script>
